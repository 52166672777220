(() => {
  'use strict';

  const module = {
    init() {
      this.load_modules();
    },

    load_module(name = '', path = 'css') {
      if ( !name ) return;

      const el = document.createElement('link');
      el.setAttribute('rel','stylesheet');
      el.setAttribute('id',name);
      el.setAttribute('type','text/css');
      el.setAttribute('media','all');
      el.setAttribute('href',`/assets/themes/mytheme/assets/${path}/${name}.css`);
      const target = document.querySelector('head');
      target.appendChild(el);
    },

    load_modules() {
      // if ( document.querySelector('.js-main.is-home') ) {
      //   this.load_module('module-home','css');
      // }

      if ( document.querySelector('.js-main.is-page') ) {
        this.load_module('module-page','css');
      }

      // if ( document.querySelector('.js-main.is-posts') ) {
      //   this.load_module('module-posts','css');
      // }

      // if ( document.querySelector('.js-main.is-catalog') ) {
      //   this.load_module('module-catalog','css');
      // }

      if ( document.querySelector('.js-main.is-cart') ) {
        this.load_module('module-cart','css');
      }

      if ( document.querySelector('.js-main.is-offer') ) {
        this.load_module('module-offer','css');
      }

      if ( document.querySelector('.js-main.is-promo-advent') ) {
        this.load_module('bundle.min','advent-promo/css');
      }
    },
  };

  module.init();
})();
